import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

type UserPreferenceState = {
  isSideNavCollpased: boolean;
  homeWidgets: { key: string; isVisible: boolean }[];
};

const initialState: UserPreferenceState = {
  isSideNavCollpased: false,
  homeWidgets: [],
};

export const UserPreferenceStore = signalStore(
  { providedIn: 'root', protectedState: true },
  withState(initialState),
  withMethods((store) => {
    const toggleSideNav = () => {
      patchState(store, { isSideNavCollpased: !store.isSideNavCollpased() });
    };
    const setHomeWidgets = (widgetKeys: { key: string; isVisible: boolean }[]) => {
      patchState(store, { homeWidgets: widgetKeys });
    };
    return {
      toggleSideNav,
      setHomeWidgets,
    };
  }),
  withStorageSync({
    key: 'user-preference-state',
    storage: () => localStorage,
  })
);
